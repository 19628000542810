<template>
    <div class="TagList">
        <div class="contenClass" v-if="dynamicTags.length>0">
            <van-checkbox-group v-model="selectedTags">
                <template v-for="(item,index) in dynamicTags">
                    <li v-if="!!item.labelType" :key="index" @click="changeOption(item.labelId)">
                        <!-- <span > -->
                        <i :style="setBgColor(item.color)" class="iconfont  icon-dot"></i>
                        <!-- </span> -->
                        {{item.labelName}}
                        <span class="chekedClass" @click.stop>
                            <van-checkbox :name="item.labelId">
                            </van-checkbox>
                        </span>
                    </li>
                </template>
            </van-checkbox-group>
        </div>
        <div class="noContenClass" v-else>
            <i class="iconfont icon-tag"></i>
            <span>暂时没有标签</span>
            <span class="nextC" @click="newTag()">马上创建</span>
        </div>
        <div class="footClass" @click="newTag()" v-if="dynamicTags.length>0"><span>新建标签</span></div>
        <van-popup v-model="show" position="right" style="width: 100%; height: 100%;">
            <tag-add ref="TagAdd" :moduleCode="moduleCode" @addTags="addTags"></tag-add>
        </van-popup>
    </div>
</template>
<script>
import { commentsColor } from '@/libs/utils.js' // tagsBgColor
import TagAdd from '../TagAdd'
import { modifyTagSuccess } from '../../../mailConst.js'
import titleMixin from '@/mixin/title'
import { mapGetters } from 'vuex'
export default {
    name: 'TagList',
    // title: '标签',
    mixins: [titleMixin],
    // props: ['boxId'],
    data() {
        return {
            dynamicTags: [], // 常规标签
            selectedTags: [], // 选中标签id
            identFieldValue: 0,
            moduleCode: '',
            show: false
        }
    },
    computed: {
        ...mapGetters('Mail', ['currentCtid'])
    },
    components: {
        'tag-add': TagAdd
    },
    created() {
    },
    mounted() {
        this.pageInit()
    },
    methods: {
        changeOption(item) {
            let index = this.selectedTags.indexOf(item)
            if (index >= 0) {
                this.selectedTags.splice(index, 1)
            } else {
                this.selectedTags.push(item)
            }
        },
        newTag() {
            this.show = true
            this.Global.utils.rightMenu.clearMenu()
            this.setHeaderTitle('新建标签')
        },
        // 停止冒泡
        stopAction(e) {
            e.stopPropagation()
        },
        setMenu() {
            let rightMenu = this.Global.utils.rightMenu.setMenu('完成')
            rightMenu.onClick(() => {
                this.submit()
            })
            this.setHeaderTitle('标签')
        },
        pageInit() {
            if (this.$route.path == '/mail/TagList') {
                let { moduleCode, selectedTags, identFieldValue } = this.$route.params
                this.moduleCode = moduleCode || ''
                this.selectedTags = selectedTags || []
                this.identFieldValue = identFieldValue || 0
                this.maillabelsGet(moduleCode)
                this.setMenu()
            }
        },
        // 获取用户标签列表
        async  maillabelsGet(moduleCode) {
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.label_get
                let res = await this.axios.get(url, { params: { searchType: 'list', moduleCode: moduleCode } })
                if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isArray(res.data.data)) {
                    this.dynamicTags = res.data.data || []
                    //  = tags.filter(item => !!item.labelType)
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        // 完成
        async  submit() {
            let data = { moduleCode: this.moduleCode, identFieldValue: this.identFieldValue, labelIds: this.selectedTags }
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                data.ctid = this.currentCtid
            } else {
                delete data.ctid
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.billLabelPut
                let res = await this.axios.post(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success('设置成功')
                    this.Global.utils.setItem(modifyTagSuccess, JSON.stringify({ date: (+new Date()), tags: this.selectedTags, mId: this.identFieldValue }))
                    this.$router.back()
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        addTags() {
            this.show = false
            this.maillabelsGet(this.moduleCode)
            this.setMenu()
        },
        // 背景色
        setBgColor(colorId) {
            return commentsColor(colorId)
        }
    },
    watch: {
        $route() {
            this.pageInit()
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
